/* Number.css */

.PhoneInput {
    text-align: center; /* Center the text inside the PhoneInput */
}

.PhoneInput input {
    text-align: center; /* Center the text inside the input field */
    color: #000; /* Makes the text inside the PhoneInput black */
}

/* Change the inner focus border color */
.PhoneInputInput:focus {
    outline: none;
    border-color: #ff5733; /* Replace with your preferred color */
    box-shadow: 0 0 0 px #ff5733; /* Adds a custom focus border */
}

h2 {
    color: #000; /* Makes the message text black */
}

.text-gray-700 {
    color: #000; /* Overrides gray text color for more black */
}

.text-black {
    color: #000; /* Utility class to set text color to black */
}

/* Ensure the PhoneInput's input text color is properly set */
.PhoneInput input::placeholder {
    color: #999; /* Adjust placeholder color if needed */
}

.custom-button {
    max-width: 200px; /* Adjust the width as needed */
    width: 100%; /* Ensure the button doesn’t exceed the maximum width */
    margin: 0 auto; /* Center the button horizontally */
    padding: 12px; /* Adjust the padding as needed */
}

/* Assuming this is in your CSS file */
body {
    background-color: white; /* Change to white or your desired color */
}

.Conts {
    background-color: white; /* Ensure your container is white */
}

.rolling-circle-loader {
    width: 80px; /* Adjust the size of the loader */
    height: 80px; /* Adjust the size of the loader */
    border: 8px solid #f3f3f3; /* Light gray background */
    border-top: 8px solid #73ec8b; /* Green color */
    border-radius: 50%; /* Make it a circle */
    animation: spin 1s linear infinite; /* Spin animation */
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
